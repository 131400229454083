import { PageProps } from 'gatsby';
import { TrackingData } from 'packages/innedit';
import React, { FC } from 'react';

import CMSView from '~/components/View';
import Form from '~/containers/Espace/Form';
import tracking from '~/params/tracking.json';
import TemplateEspace from '~/templates/espace';
import requireEspace, { EspaceProps } from '~/utils/requireEspace';
import { UserProps } from '~/utils/requireUser';

const PageEspaceTaskTrackingUpdate: FC<
  PageProps & EspaceProps & UserProps
> = props => {
  const {
    espace,
    user,
    params: { trackingId },
  } = props;

  return (
    <TemplateEspace espace={espace} user={user}>
      <CMSView>
        <Form
          docId={trackingId}
          model={new TrackingData({ espaceId: espace.id, params: tracking })}
          type="update"
        />
      </CMSView>
    </TemplateEspace>
  );
};

export default requireEspace(PageEspaceTaskTrackingUpdate);
